.loader {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	vertical-align: middle;
}

.loader-bars:before,
.loader-bars:after,
.loader-bars span {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 30px;
	height: 90px;
	background-color: #00255f;
	-webkit-animation: grow 1.5s linear infinite;
	animation: grow 1.5s linear infinite;
}

.loader-bars:after {
	left: 45px;
	-webkit-animation: grow 1.5s linear -.5s infinite;
	animation: grow 1.5s linear -.5s infinite;
}

.loader-bars span {
	left: 90px;
	-webkit-animation: grow 1.5s linear -1s infinite;
	animation: grow 1.5s linear -1s infinite;
}

.toggle-component {
    font-size: 2rem;
    color: #171717;
    width: 50px;
    height: 20px;
    background: #777777;
    border: 1px solid #555;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    transition: border-color background-color 0.3s;
    margin: 10px;
  }
  .toggle-component.active {
    background: #7bbde4;
    border-color: #dfdfdf;
  }
  .toggle-component.active .toggle-button {
    right: -2px;
  }
  .toggle-component .toggle-button {
    position: absolute;
    top: -3px;
    right: 50%;
    height: 26px;
    width: 26px;
    background: #dfdfdf;
    box-shadow: 0 0 5px #555;
    border-radius: 50%;
    transition: right 0.3s;
  }

.toggle-component-disabled {
    font-size: 2rem;
    color: #171717;
    width: 50px;
    height: 20px;
    background: #a8a8a8;
    border: 1px solid #a8a8a8;
    position: relative;
    border-radius: 10px;
    transition: border-color background-color 0.3s;
    margin: 10px;
  }

  .toggle-component-disabled.active .toggle-button-disabled {
    right: -2px;
  }
  .toggle-component-disabled .toggle-button-disabled {
    position: absolute;
    top: -3px;
    right: 50%;
    height: 26px;
    width: 26px;
    background: #606267;
    box-shadow: 0 0 1px #606267;
    border-radius: 50%;
    transition: right 0.3s;
  }

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radiocircle:after {
  content: "";
  position: absolute;
  display: none;
}

.multiSelect input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.multiSelect input:focus ~ .checkmark {
  outline: 2px auto rgb(229, 151, 0);
  outline-offset: -2px;
  }


.multiSelect input:focus ~ .radiocircle {
  outline: 2px auto rgb(229, 151, 0);
  outline-offset: -2px;
  }


@keyframes grow {
	0% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
	50% { -webkit-transform: scaleY(1); transform: scaleY(1); opacity: 1;}
	100% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
}

  .scroller::-webkit-scrollbar {
    width: 4px;
    /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
.scroller::-webkit-scrollbar-track {
    background-color: #ebf8ff;
    /*background: red;*/
}
.scroller::-webkit-scrollbar-thumb {
    background-color: #bee3f8;
    /*outline: 1px solid slategrey;*/
}

.scroller {
overflow: scroll;
display: inline-block;
scrollbar-color: #bee3f8 #ebf8ff; 
}
